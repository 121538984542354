import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';

import { analyticAction } from 'ducks/analytic';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import { getVendorConfigs, mapError } from '../util';

const microformScriptId = 'MicroformScript';
const fingerprintScriptId = 'FingerprintScript';

const getMicroformScriptElement = () => {
  return document.getElementById(microformScriptId);
};
const getFingerPrintScriptElement = () => {
  return document.getElementById(fingerprintScriptId);
};

function LoadScript({
  paymentSessionsData,
  onLoaded = () => {},
  onError = () => {},
  getFeatureFlags,
}) {
  const dispatch = useDispatch();
  const [isMicroformScriptLoaded, setMicroformScriptLoaded] = useState(false);
  const [
    fingerprintScriptLoadedTimestamp,
    setFingerprintScriptLoadedTimestamp,
  ] = useState(false);
  const isScriptErroredRef = useRef(false);

  const isScriptErrored = () => {
    return isScriptErroredRef.current;
  };

  const setScriptErrored = () => {
    isScriptErroredRef.current = true;
  };

  const errorHandler = ({ message, source }) => {
    if (!isScriptErrored()) {
      onError({ message });

      dispatch(
        analyticAction({
          eventName: 'Checkout Form Loaded Failure',
          ...(source && { source }),
        }),
      );
      setScriptErrored();
    }
  };

  useEffect(() => {
    isMicroformScriptLoaded &&
      fingerprintScriptLoadedTimestamp &&
      isFunction(onLoaded) &&
      onLoaded(fingerprintScriptLoadedTimestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicroformScriptLoaded, fingerprintScriptLoadedTimestamp]);

  useEffect(() => {
    // isMounted is used to avoid the error below
    // Warning: Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    let isMounted = true;

    const { fingerprintScript: fingerprintScriptUrl = '' } = getVendorConfigs(
      getFeatureFlags('PAYMENT_CONFIG'),
    );

    const {
      orgId,
      merchantId,
      sessionId,
      clientLibrary,
      clientLibraryIntegrity,
    } = paymentSessionsData || {};

    if (
      !(
        orgId &&
        merchantId &&
        sessionId &&
        clientLibrary &&
        clientLibraryIntegrity
      )
    ) {
      errorHandler({
        message: mapError(3),
        source: 'paymentSessionsData',
      });
    } else {
      let microformScriptOnLoad = false;
      if (!getMicroformScriptElement()) {
        const microformScript = document.createElement('script');
        microformScript.setAttribute('src', clientLibrary);
        microformScript.id = microformScriptId;
        // The below will be uncommented when the cybersource fixes the microform
        // microformScript.setAttribute('integrity', clientLibraryIntegrity);
        // microformScript.setAttribute('crossorigin', 'anonymous');
        microformScriptOnLoad = () => {
          isMounted && setMicroformScriptLoaded(true);
        };
        microformScript.onload = microformScriptOnLoad;
        microformScript.onerror = (e) => {
          errorHandler({
            message: mapError(1),
            source: 'microform',
          });
        };
        document.head.append(microformScript);
      }

      let fingerprintScriptOnLoad = false;
      if (!getFingerPrintScriptElement()) {
        const fingerprintScript = document.createElement('script');
        fingerprintScript.setAttribute(
          'src',
          `${fingerprintScriptUrl}?org_id=${encodeURIComponent(
            orgId,
          )}&session_id=${encodeURIComponent(`${merchantId}${sessionId}`)}`,
        );
        fingerprintScript.id = fingerprintScriptId;
        fingerprintScriptOnLoad = () => {
          isMounted && setFingerprintScriptLoadedTimestamp(Date.now());
        };
        fingerprintScript.onload = fingerprintScriptOnLoad;
        fingerprintScript.onerror = () => {
          errorHandler({
            message: mapError(2),
            source: 'fingerprint',
          });
        };
        document.head.append(fingerprintScript);
      }

      return () => {
        isMounted = false;
        const microformScriptElement = getMicroformScriptElement();
        if (microformScriptElement && microformScriptOnLoad) {
          microformScriptElement.removeEventListener(
            'load',
            microformScriptOnLoad,
          );
          document.head.removeChild(microformScriptElement);
        }
        const fingerPrintScriptElement = getFingerPrintScriptElement();
        if (fingerPrintScriptElement && fingerprintScriptOnLoad) {
          fingerPrintScriptElement.removeEventListener(
            'load',
            fingerprintScriptOnLoad,
          );
          document.head.removeChild(fingerPrintScriptElement);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default React.memo(withFeatureFlags(LoadScript));
