import React, { useState } from 'react';

import CircularInfoIcon from 'components/ui/ui-kit/Icons/CircularInfoIcon';

import styled, { css } from 'styled-components';
import { useClickOutside as ClickOutside } from 'utils/hooks';

const TooltipItem = styled.div`
  display: none;
  visibility: hidden;
  opacity: 0;
  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      visibility: visible;
      display: block;
    `}
`;

const Tooltip = ({ allowedCardTypes = [] }) => {
  const [tooltip, setTooltip] = useState(false);
  return (
    <>
      <span className={`absolute pin-t pin-r mr-8 mt-16 cursor-pointer`}>
        <button
          onMouseEnter={() => setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
          onClick={() => setTooltip(true)}
        >
          <CircularInfoIcon />
        </button>
      </span>
      <ClickOutside callback={() => setTooltip(false)}>
        <TooltipItem isActive={tooltip} className={`cvv-tooltip`}>
          <div className={`cvv-border-triangle`} />
          <div
            className={`
              cvv-content
              px-16
              py-8
              bg-lightest-grey
              text-black
              z-99999
              absolute
              border-lightest-grey
              rounded-lg
            `}
          >
            <p className={`text-sm leading-1.29`}>
              <span className={`font-MarkProBold`}>
                Visa® and Mastercard® cards:
              </span>{' '}
              Three digit CVV code is located on the back of your credit/debit
              card on the right side of the white signature strip.
              <br />
              <br />
              {allowedCardTypes.includes('amex') && (
                <span>
                  <span className={`font-MarkProBold`}>
                    American Express® card:
                  </span>{' '}
                  Four digit CVV code is located on the front right side of the
                  card.
                </span>
              )}
            </p>
          </div>
        </TooltipItem>
      </ClickOutside>
    </>
  );
};

export default Tooltip;
