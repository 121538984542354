import React from 'react';
import { isEmpty } from 'lodash';

import CardDetailsInput from './CardDetailsInput';
import Button from './ui-kit/Button';
import ActionModal from './ActionModal';
import CardDetailsInputV2 from './PaymentForm/CardDetailsInputV2';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import {
  selectPaymentVendor,
  isCybersource,
  getVendorConfigs,
  getErrorMessageCybersource,
  isErrorCybersource,
} from 'lib/cybersource/util';
import Alerts from './ui-kit/Alerts';

class PaymentDetailsInput extends React.Component {
  state = {
    error: false,
  };

  onToken = (token) => {
    if (!isEmpty(token?.error)) {
      // handle error here to display error within the modal
      this.setState({ error: token?.error });
      this.setState({ inProgress: false });
    } else {
      if (this.props.onToken) {
        this.props.onToken(token);
      }
    }
  };

  render() {
    const { onClose, details, getFeatureFlags } = this.props;
    const paymentConfig = getFeatureFlags('PAYMENT_CONFIG') || {};
    const { defaultErrorMessage, ctaErrorMessageHtml } = getVendorConfigs(
      paymentConfig,
    );

    const { paymentGateway: currentPaymentGateway } = details || {};

    // need to add additional condition for test users
    const selectedVendor = selectPaymentVendor({
      currentPaymentGateway,
      paymentConfig,
    });

    const CardDetailsInputComponent = isCybersource(selectedVendor)
      ? CardDetailsInputV2
      : CardDetailsInput;

    const title = !isEmpty(details)
      ? 'Update Payment Details'
      : 'Add Payment Details';

    let errorMessage = this.state.error?.message;
    if (isErrorCybersource(errorMessage)) {
      errorMessage = getErrorMessageCybersource(errorMessage, paymentConfig);
    }
    return (
      <ActionModal
        onBackClicked={onClose}
        title={title}
        content={
          <>
            <CardDetailsInputComponent
              onValidate={(valid) => this.setState({ canUpdate: valid })}
              details={details}
              getToken={this.state.inProgress}
              onToken={this.onToken}
            />
            {errorMessage && (
              <Alerts
                className={'mt-32 text-left'}
                type='failure'
                title={errorMessage || defaultErrorMessage || ''}
                messageHtml={ctaErrorMessageHtml || ''}
                open={true}
                showClose={false}
              />
            )}
          </>
        }
        renderActions={
          <>
            <Button
              type='primary'
              className='mb-16 flex justify-center w-full'
              width='w-full'
              text='Update'
              disabled={!this.state.canUpdate}
              handler={() => this.setState({ inProgress: true })}
              loading={this.state.inProgress}
              rounded
            />
          </>
        }
      />
    );
  }
}

export default withFeatureFlags(PaymentDetailsInput);
