import React from 'react';
import PropTypes from 'prop-types';
import Message from './Message';

class Alerts extends React.Component {
  render() {
    const {
      title,
      message,
      messageHtml,
      actions,
      open,
      onClose,
      type,
      showClose,
      showIcon,
      className,
      titleClassName,
      backgroundClassName,
    } = this.props;
    return (
      <div
        className={`os-transition ${
          open ? 'os-transition-show-message' : 'os-transition-hide-message'
        } ${className}`}
      >
        <Message
          type={type}
          open
          title={title}
          message={message}
          messageHtml={messageHtml}
          actions={actions}
          showIcon={showIcon}
          showClose={showClose}
          onClose={onClose}
          titleClassName={titleClassName}
          backgroundClassName={backgroundClassName}
        />
      </div>
    );
  }
}

Alerts.propTypes = {
  /** Title text for this alert. Defaults to ``null``. */
  title: PropTypes.string,
  /** Whether to show a close button for this alert. */
  showClose: PropTypes.bool,
  /** Message to show */
  message: PropTypes.string.isRequired,
  /** HTML Message content to show */
  messageHtml: PropTypes.string,
  /** Whether to show the alert icons. Defaults to true */
  showIcon: PropTypes.bool,
  /** Type of this message.. */
  type: PropTypes.oneOf(['success', 'failure', 'warning']),
  /** If true, `Message` is opened. */
  open: PropTypes.bool,
  /** Function to invoke when the user clicks on the close button.*/
  onClose: PropTypes.func,
  /** Node with action link button */
  actions: PropTypes.node,
  /** Any additional Tailwind classes to apply */
  className: PropTypes.string,
  /** Any additional Tailwind classes to apply for title */
  titleClassName: PropTypes.string,
  /** Background color override */
  backgroundClassName: PropTypes.string,
};

//Default props
Alerts.defaultProps = {
  title: null,
  message: '',
  messageHtml: '',
  width: 'w-full',
  type: 'success',
  showIcon: true,
  open: false,
  onClose: null,
  actions: null,
  className: '',
  titleClassName: 'font-MarkProBold leading-1.14',
  backgroundClassName: 'bg-dark-grey',
};

export default Alerts;
