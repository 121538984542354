import { get, isNumber, isObject } from 'lodash';
import queryString from 'query-string';

import { CybersourceKey } from './const';

const BamboraKey = 'bambora';

export function hasCybersourceParam() {
  const search = get(window, 'location.search', '');
  const searchParams = queryString.parse(search);
  const useCybersource = get(searchParams, 'useCybersource', false);
  return useCybersource;
}

export function selectPaymentVendor({ currentPaymentGateway, paymentConfig }) {
  const hasCybersource = hasCybersourceParam();
  const { vendor, useCurrentVendor } = paymentConfig || {};
  if (hasCybersource) return CybersourceKey;
  if (useCurrentVendor && currentPaymentGateway) return currentPaymentGateway;
  return vendor === CybersourceKey ? CybersourceKey : BamboraKey;
}

export function isCybersource(selectedPaymentVendor) {
  return selectedPaymentVendor === CybersourceKey;
}

export function getVendorConfigs(paymentConfig) {
  const { vendor_config } = paymentConfig || {};
  return vendor_config;
}

export function getErrorMessageCybersource(errorMessage, paymentConfig) {
  // error code
  // 0: paymentSessions
  // 1: microform
  // 2: fingerprint
  // 3: paymentSessionsData
  // 4: microform form
  // 5: token error by cybersource
  const { defaultErrorMessage } = getVendorConfigs(paymentConfig) || '';
  let returnMessage = defaultErrorMessage || '';
  if (errorMessage?.type === CybersourceKey) {
    const errorCode = isNumber(errorMessage?.code)
      ? `(#${errorMessage?.code})`
      : '';
    returnMessage = `${defaultErrorMessage} ${errorCode}`;
  }
  return returnMessage;
}

export function isErrorCybersource(errorMessage) {
  return (
    isObject(errorMessage) &&
    errorMessage?.type === CybersourceKey &&
    isNumber(errorMessage?.code)
  );
}

export function mapError(code) {
  return {
    type: CybersourceKey,
    code: isNumber(code) ? code : 0,
  };
}
