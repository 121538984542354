import API from '@aws-amplify/api';
import * as types from '../constants/actionTypes';
import { getUserId } from 'selectors/user';
import initialState from 'reducers/initialState';
import { dispatchAnalytic } from 'ducks/analytic';
import { mapError } from 'lib/cybersource/util';

export function getPaymentSessions() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = getUserId(state?.user);
    dispatch(getPaymentSessionsStart());
    const path = `/creditCard/users/${userId}/paymentSessions`;
    const params = {
      body: {},
    };
    // need to post empty body unless it will response 400
    return API.post('fe-api-getPaymentSessions', path, params)
      .then((result) => {
        const data = result?.data;

        dispatch(getPaymentSessionsSuccess(data));
      })
      .catch((error) => {
        dispatch(getPaymentSessionsFailure());
      });
  };
}

export function getPaymentSessionsReset() {
  const state = initialState?.paymentSessions;
  return {
    type: types.SET_PAYMENT_SESSIONS,
    state,
  };
}
export function getPaymentSessionsStart() {
  const state = {
    isReady: false,
    inProgress: true,
    isSuccess: false,
    data: {},
    error: {},
  };
  return {
    type: types.SET_PAYMENT_SESSIONS,
    state,
  };
}

export function getPaymentSessionsSuccess(data) {
  const state = {
    isReady: true,
    inProgress: false,
    isSuccess: true,
    data,
    error: {},
  };
  return {
    type: types.SET_PAYMENT_SESSIONS,
    state,
  };
}

export function getPaymentSessionsFailure() {
  dispatchAnalytic({
    name: 'Checkout Form Loaded Failure',
    option: { source: 'paymentSessions' },
  });

  const state = {
    isReady: true,
    inProgress: false,
    isSuccess: false,
    data: {},
    error: { message: mapError(0) },
  };
  return {
    type: types.SET_PAYMENT_SESSIONS,
    state,
  };
}
