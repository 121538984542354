import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FormSection from './ui-kit/FormSection';
import Input from './ui-kit/Input';
import Button from './ui-kit/Button';
import CheckBox from './ui-kit/CheckBox';
import Label from './ui-kit/Label';
import _ from 'lodash';
import Message from './ui-kit/Message';
import {
  getFormSectionProps,
  isCurrentStage,
  completeCurrentStage,
} from '../../ducks/stepper';
import { isLoggedIn, createAccount, legacyCreateUser } from '../../ducks/user';
import { loginUser } from '../../actions/user';
import DatePicker from './DatePicker';
import SelectBox from './ui-kit/SelectBox';
import { postCodeRegex, genderSelectValues } from 'utils/profile';
import { withFeatureFlags } from '../context/withFeatureFlags';
import InternationalPhoneNumberInput from './InternationalPhoneNumberInput';

class CreateAccount extends Component {
  state = {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    postcode: null,
    gender: null,
    checkedTnC: false,
    // checkedAdTracking: true,
    // checkedOfferPromo: true,
    validPassword: false,
    validFirstName: false,
    validLastName: false,
    validPostCode: true,
    inProgress: false,
    isSuccess: false,
    showFailure: false,
    allFieldsValid: false,
    subscriptionUserInProgress: false,
    updateVoucherSubmitButton: false,
    loggedIn: false,
    lockForm: false,
    dateOfBirth: null,
    phoneNumber: null,
    validPhoneNumber: false,
  };

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { account } = this.props;
    const { created: prevCreated } = prevProps.account;
    const { created } = account;
    const hasTransitionedToCreated = !prevCreated && created;
    if (hasTransitionedToCreated) {
      this.props.completeCurrentStage();
      this.loginUser();
      return;
    }
    const { showFailure } = this.state;
    const { error: prevAccountError } = prevProps.account;
    const { error: currAccountError } = account;
    const hasTransitionedToAccountError = !prevAccountError && currAccountError;
    if (!showFailure && hasTransitionedToAccountError) {
      this.setState({
        showFailure: true,
        inProgress: false,
        lockForm: false,
      });
      return;
    }
    const { inProgress: prevInProgress } = prevState;
    const { inProgress: currInProgress } = this.state;
    const hasTransitionedToInProgress = !prevInProgress && currInProgress;
    if (hasTransitionedToInProgress) {
      this.setState({ showFailure: false });
      // TODO: Decouple voucher related stuff
      const updateVoucherSubmitButton = _.get(
        this.props.user,
        'createUserByVoucher.updateVoucherSubmitButton',
      );
      const handler = updateVoucherSubmitButton
        ? this.onRetrySubmit
        : this.onSubmit;
      handler();
      return;
    }
    const { error: prevError } = prevProps.voucher;
    const { error: currError } = this.props.voucher;
    const { loginFailure } = this.props.user;
    const hasTransitionedToError = !prevError && (currError || loginFailure);

    const { stepper, stage } = this.props;
    if (
      isCurrentStage(stepper, stage) &&
      !showFailure &&
      hasTransitionedToError
    ) {
      // TODO: Refactor error handling to decouple for voucher
      this.setState({ showFailure: true, inProgress: false });
      // Copied from previous logic.
      // TODO: Decouple voucher related stuff
      const updateVoucherSubmitButton = _.get(
        this.props.user,
        'createUserByVoucher.updateVoucherSubmitButton',
      );
      if (updateVoucherSubmitButton) {
        this.setState({
          updateVoucherSubmitButton,
          lockForm: true,
        });
      }
    }
  }

  firstNameChange = (value) => {
    this.setState({ firstName: value });
  };

  lastNameChange = (value) => {
    this.setState({ lastName: value });
  };

  validateName(name) {
    const validNamelReg = /^[a-z-]+( [a-z-]+)*$/i;
    return (
      typeof name === 'string' && validNamelReg.test(name) && name.length <= 40
    );
  }

  validateFirstName(firstName) {
    if (!_.isEmpty(firstName) && this.validateName(firstName)) {
      this.setState({ validFirstName: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validFirstName: false }, this.allFieldsAreValidated);
    return false;
  }

  validateLastName(lastName) {
    if (!_.isEmpty(lastName) && this.validateName(lastName)) {
      this.setState({ validLastName: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validLastName: false }, this.allFieldsAreValidated);
    return false;
  }

  phoneNumberChange = (value) => {
    if (_.isEmpty(value) || !this.validatePhoneNumber(value)) {
      return false;
    } else {
      this.setState({ phoneNumber: value });
    }
  };

  dateOfBirthChange = (value) => {
    this.setState({ dateOfBirth: value });
  };

  validatePhoneNumber = (phoneNumber) => {
    const validPhoneNumberReg = /^[0][4]\d{8}$|^[1-9]\d{8}$/;
    const validPhoneNumber =
      typeof phoneNumber === 'string' &&
      validPhoneNumberReg.test(phoneNumber.replace(/\s+/g, ''));

    if (!_.isEmpty(phoneNumber) && validPhoneNumber) {
      this.setState({ validPhoneNumber: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validPhoneNumber: false }, this.allFieldsAreValidated);
    return false;
  };

  postcodeChanged = (value) => {
    this.setState({ postcode: value });
  };

  validatePostCode = (postcode) => {
    if (_.isEmpty(postcode) || postCodeRegex.test(postcode)) {
      this.setState({ validPostCode: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validPostCode: false }, this.allFieldsAreValidated);
    return false;
  };

  allFieldsAreValidated = () => {
    const {
      checkedTnC,
      validFirstName,
      validLastName,
      validPassword,
      validPhoneNumber,
      validPostCode,
    } = this.state;
    const { getFeatureFlags } = this.props;
    const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
      'FEATURE_PHONE_CODE_VERIFICATION',
    );
    const isPhoneNumberValid = FEATURE_PHONE_CODE_VERIFICATION
      ? true
      : validPhoneNumber;
    if (
      checkedTnC &&
      validFirstName &&
      validLastName &&
      validPassword &&
      validPostCode &&
      isPhoneNumberValid
    ) {
      this.setState({ allFieldsValid: true });
    } else {
      this.setState({ allFieldsValid: false });
    }
  };

  passwordChange = (value) => {
    this.setState({ password: value });
  };

  validatePassword(password) {
    const validPasswordReg = /(?=[a-zA-Z0-9!@#$%^&*()-_=+<>?[\]{}|~`';":,.]*$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/;
    if (
      !_.isEmpty(password) &&
      typeof password === 'string' &&
      validPasswordReg.test(password)
    ) {
      this.setState({ validPassword: true }, this.allFieldsAreValidated);
      return true;
    }
    this.setState({ validPassword: false }, this.allFieldsAreValidated);
    const { onInvalidPassword } = this.props;
    if (onInvalidPassword) {
      onInvalidPassword();
    }
    return false;
  }

  handleOnTnCCheck = (checkedTnC) => {
    this.setState({ checkedTnC }, this.allFieldsAreValidated);
  };

  createUser = () => {
    const {
      password,
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      postcode,
      // checkedAdTracking,
      // checkedOfferPromo,
      gender,
    } = this.state;
    const { getFeatureFlags } = this.props;
    const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
      'FEATURE_PHONE_CODE_VERIFICATION',
    );
    const { stepper, verification } = this.props;
    const { token } = verification;
    const { data } = stepper;
    const { email, number } = data;
    const userName = email;
    const mobilePhoneNumber = FEATURE_PHONE_CODE_VERIFICATION
      ? number
      : phoneNumber;
    const userInfo = {
      email: userName,
      userName,
      password,
      firstName,
      lastName,
      ...(!_.isEmpty(postcode) && { postcode }),
      ...(!_.isEmpty(gender) && { gender }),
      mobileNumber: mobilePhoneNumber,
      dateOfBirth,
      token,
    };
    if (FEATURE_PHONE_CODE_VERIFICATION) {
      this.props.createAccount(userInfo);
      return;
    }
    this.props.legacyCreateUser(userInfo);
  };

  loginUser = () => {
    const { password } = this.state;
    const { stepper } = this.props;
    const {
      data: { email },
    } = stepper;
    const userName = email;
    this.props.loginUser({
      email: userName,
      password: password,
    });
  };

  onSubmit = () => {
    this.setState({ lockForm: true }, () => this.createUser());
    this.triggerGAE();
  };

  onRetrySubmit = () => {
    this.setState({ lockForm: true }, () => this.loginUser());
    this.triggerGAE();
  };

  triggerGAE = () => {
    const { onSubmit, getFeatureFlags } = this.props;
    const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
      'FEATURE_PHONE_CODE_VERIFICATION',
    );
    if (onSubmit) {
      const allFieldsValid = _.get(this.state, 'allFieldsValid');
      const validPassword = _.get(this.state, 'validPassword');
      const validPhoneNumber = FEATURE_PHONE_CODE_VERIFICATION
        ? true
        : _.get(this.state, 'validPhoneNumber');
      const checkedTnC = _.get(this.state, 'checkedTnC');
      onSubmit({
        allFieldsValid,
        validPassword,
        validPhoneNumber,
        checkedTnC,
      });
    }
  };

  // This is necessary due to Chrome's autofill behaviour
  renderAutoFillBuster() {
    return (
      <Input className={`hidden`} type='password' name='hidden' value='1234' />
    );
  }

  render() {
    const {
      firstName,
      lastName,
      postcode,
      checkedTnC,
      // checkedAdTracking,
      // checkedOfferPromo,
      inProgress,
      showFailure,
      allFieldsValid,
      subscriptionUserInProgress,
      lockForm,
      phoneNumber,
    } = this.state;

    const {
      stepper,
      user,
      stage,
      showFields = [],
      type = '',
      getFeatureFlags,
    } = this.props;
    const FEATURE_PHONE_CODE_VERIFICATION = getFeatureFlags(
      'FEATURE_PHONE_CODE_VERIFICATION',
    );
    const isInternationalPhoneAllowed = getFeatureFlags(
      'FEATURE_INTERNATIONAL_PHONE_ALLOWED',
    );
    const { data } = stepper;
    const { email, number, nationalNumber } = data;
    const phoneProps = FEATURE_PHONE_CODE_VERIFICATION && {
      showLockedIcon: true,
      id: 'phone-number-locked',
      disabled: true,
      value: nationalNumber || number,
      type: `tel`,
    };

    const shouldRender = !isLoggedIn(user);
    const formSectionProps = getFormSectionProps(stepper, stage);
    return (
      shouldRender && (
        <FormSection label={this.props.label} {...formSectionProps}>
          <div className='w-full flex flex-wrap sm:flex-no-wrap'>
            {this.renderAutoFillBuster()}
            <Input
              show={showFields.includes(`firstName`)}
              type='string'
              id='user-first-name'
              name='firstName'
              ref={(firstNameEl) => {
                this.firstNameEl = firstNameEl;
              }}
              labelText='First name *'
              disabled={lockForm}
              placeHolder='e.g John'
              width='w-full sm:w-1/2'
              value={firstName}
              changed={this.firstNameChange}
              validate={true}
              showIcon={lockForm ? false : true}
              validator={(value, e) => {
                return !this.validateFirstName(value)
                  ? 'Invalid first name provided.'
                  : null;
              }}
            />
            <Input
              show={showFields.includes(`lastName`)}
              type='string'
              id='user-last-name'
              name='lastName'
              ref={(lastNameEl) => {
                this.lastNameEl = lastNameEl;
              }}
              className='sm:ml-16 xl:ml-48'
              labelText='Last name *'
              disabled={lockForm}
              placeHolder='e.g Smith'
              width='w-full sm:w-1/2'
              value={lastName}
              changed={this.lastNameChange}
              validate={true}
              showIcon={lockForm ? false : true}
              validator={(value, e) => {
                return !this.validateLastName(value)
                  ? 'Invalid last name provided.'
                  : null;
              }}
            />
          </div>
          <div className='w-full flex flex-wrap sm:flex-no-wrap text-left sm:mt-16'>
            {this.renderAutoFillBuster()}
            {(!isInternationalPhoneAllowed ||
              FEATURE_PHONE_CODE_VERIFICATION) && (
              <Input
                show={
                  FEATURE_PHONE_CODE_VERIFICATION
                    ? showFields.includes(`phoneNumber`)
                    : true
                }
                type='number'
                id='phone-number'
                name='phoneNumber'
                ref={(phoneNumber) => {
                  this.phoneNumber = phoneNumber;
                }}
                labelText='Mobile Phone *'
                disabled={lockForm}
                placeHolder='0400123456'
                width='w-full sm:w-1/2'
                maxLength={10}
                value={phoneNumber}
                changed={this.phoneNumberChange}
                validate
                showIcon={lockForm ? false : true}
                validator={(value, e) => {
                  return lockForm && phoneNumber
                    ? true
                    : !this.validatePhoneNumber(value)
                    ? 'This isn’t a valid Australian mobile number.'
                    : null;
                }}
                disableSpinButton
                {...phoneProps}
              />
            )}
            {isInternationalPhoneAllowed && !FEATURE_PHONE_CODE_VERIFICATION && (
              <InternationalPhoneNumberInput
                width='w-full sm:w-1/2'
                containerClass=''
                onValidate={({ number, isValid }) => {
                  if (isValid) {
                    this.setState(
                      { validPhoneNumber: true, phoneNumber: number },
                      this.allFieldsAreValidated,
                    );
                  } else {
                    this.setState(
                      { validPhoneNumber: false },
                      this.allFieldsAreValidated,
                    );
                    return `This isn't a valid mobile number.`;
                  }
                }}
              />
            )}
            <div className='w-full sm:w-1/2 sm:ml-16 xl:ml-48 '>
              <Input
                show={
                  FEATURE_PHONE_CODE_VERIFICATION
                    ? showFields.includes(`email`)
                    : true
                }
                type='string'
                id='user-email-locked'
                name='email'
                ref={(emailEl) => {
                  this.emailEl = emailEl;
                }}
                width='w-full'
                labelText='Email address *'
                disabled={true}
                placeHolder='john@email.com'
                value={email}
                showLockedIcon={true}
              />
            </div>
          </div>
          <div className='w-full flex flex-wrap sm:flex-no-wrap text-left sm:mt-16'>
            {this.renderAutoFillBuster()}
            <DatePicker
              show={showFields.includes(`dateOfBirth`)}
              id='user-dateOfBirth'
              name='dateOfBirth'
              labelText='Date of birth'
              placeHolder='Select your birth date'
              showIcon
              containerClass='w-full sm:w-1/2'
              width='w-full'
              disabled={lockForm}
              showLockedIcon={lockForm}
              onChange={this.dateOfBirthChange}
            />
            <div className='w-full sm:w-1/2 sm:ml-16 xl:ml-48'>
              <Label className='font-bold'>Gender</Label>
              <SelectBox
                width='w-full mt-16'
                placeHolder='Select your gender'
                items={genderSelectValues}
                changed={(obj) => {
                  this.setState({ gender: obj.value });
                }}
                isFormField={true}
                disabled={lockForm}
              />
            </div>
          </div>
          <div
            className='w-full flex flex-wrap sm:flex-no-wrap mt-16'
            onClick={(e) => e.stopPropagation()}
          >
            {this.renderAutoFillBuster()}
            <Input
              show={showFields.includes(`postcode`)}
              type='string'
              id='user-postcode'
              name='postcode'
              ref={(postcodeEl) => {
                this.postcodeEl = postcodeEl;
              }}
              labelText='Postcode'
              disabled={lockForm}
              placeHolder='e.g 2000'
              width='w-full sm:w-1/2'
              value={postcode}
              changed={this.postcodeChanged}
              validate={true}
              showIcon={lockForm ? false : true}
              validator={(value, e) => {
                return !this.validatePostCode(value)
                  ? 'Please enter a valid Australian postcode'
                  : value
                  ? null
                  : '';
              }}
            />
            <div className='w-full sm:w-1/2 sm:ml-16 xl:ml-48'>
              <Input
                show={showFields.includes(`password`)}
                type='password'
                id='user-password'
                name='password'
                ref={(passwordEl) => {
                  this.passwordEl = passwordEl;
                }}
                labelText='Password *'
                disabled={lockForm}
                placeHolder='Choose a strong password'
                width='w-full'
                changed={this.passwordChange}
                showIcon={lockForm ? false : true}
                validate={true}
                validator={(value, e) => {
                  return !this.validatePassword(value)
                    ? 'Password is not valid.'
                    : null;
                }}
                marginBottom='8'
              />
              <div className='w-full'>
                <Label textSize='text-xs leading-loose font-MarkProBold text-light-grey'>
                  Password must be 8 characters long and include one number and
                  one capital letter.
                </Label>
              </div>
            </div>
          </div>
          <div
            className='w-full sm:w-1/2 flex flex-col p-16 mt-24 bg-darkest-grey rounded-xl'
            onClick={(e) => e.stopPropagation()}
          >
            {/*<CheckBox
              name='personalisedAdTracking'
              checked={checkedAdTracking}
              changed={(value) => {
                this.setState({ checkedAdTracking: value });
              }}
              id='accept-ad-tracking'
            >
              <Label
                className='text-sm leading-normal'
                htmlFor='accept-ad-tracking'
              >
                <span>Personalised Ad Tracking</span>
                <br />
                <span className='text-light-grey text-xs'>
                  Helps improve the relevance of the ads you see
                </span>
              </Label>
            </CheckBox>
            <CheckBox
              name='offerAndPromos'
              className='mt-24'
              checked={checkedOfferPromo}
              changed={(value) => {
                this.setState({ checkedOfferPromo: value });
              }}
              id='accept-offer-promos'
            >
              <Label
                className='text-sm leading-normal'
                htmlFor='accept-offer-promos'
              >
                <span>Offer and Promotions</span>
                <br />
                <span className='text-light-grey text-xs'>
                  Receive exclusive offers and promotions
                </span>
              </Label>
            </CheckBox>*/}
            <CheckBox
              name='acceptTerms'
              className='mt-24'
              checked={checkedTnC}
              changed={this.handleOnTnCCheck}
              id='accept-terms'
            >
              <Label className='text-sm leading-normal' htmlFor='accept-terms'>
                {`I accept the `}
                <a
                  href='https://sport.optus.com.au/terms'
                  target='_blank'
                  className='text-teal no-underline hover:text-teal-dark active:text-teal-light os-transition-btn'
                  rel='noopener noreferrer'
                >
                  terms and conditions
                </a>
                {` and `}
                <a
                  href='https://optus.com.au/privacy'
                  target='_blank'
                  className='text-teal no-underline hover:text-teal-dark active:text-teal-light os-transition-btn'
                  rel='noopener noreferrer'
                >
                  privacy policy
                </a>
                *
              </Label>
            </CheckBox>
          </div>
          <div className='w-full sm:w-1/2' onClick={(e) => e.stopPropagation()}>
            <div className='md:w-353 pt-32'>
              <Label textSize='text-xs leading-loose font-MarkPro text-light-grey'>
                We collect personal information which may be used to supply you
                with products, set up your account and payments and for the
                other purposes described in our privacy policy. I agree to the
                Privacy Policy and the Optus Sport Terms and Conditions.
              </Label>
            </div>
            <div className='mt-10 mb-10'>
              {showFailure && (
                <Message
                  type='failure'
                  className='mt-8 mb-16'
                  message={`Sorry, we encountered an error${
                    type === 'basic-signup'
                      ? ''
                      : ' and the code was not redeemed'
                  }. Please try again. If the error persists, please contact Optus Sport support.`}
                  open={true}
                />
              )}
            </div>
            <div
              className={`${
                allFieldsValid ? 'pointer-events-auto' : 'pointer-events-none'
              }`}
            >
              <Button
                text='Agree and create account'
                type='primary'
                loading={inProgress || subscriptionUserInProgress}
                handler={() => this.setState({ inProgress: true })}
                width='w-full mt-24'
                rounded
                disabled={!allFieldsValid}
              />
            </div>
          </div>
        </FormSection>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    voucher: state.voucher,
    user: state.user,
    verification: state.verification,
    account: state.account,
    stepper: state.stepper,
  };
};

const mapDispatchToProps = {
  completeCurrentStage,
  createAccount,
  legacyCreateUser,
  loginUser,
};

export default compose(
  withFeatureFlags,
  connect(mapStateToProps, mapDispatchToProps),
)(CreateAccount);
